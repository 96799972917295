import { createSlice } from '@reduxjs/toolkit';

// project imports
import axios from 'utils/axios';

const initialState = {
    dashboards: [],
    dashboard: null,
    dashboardError: null,
    defaultDashboard: 0,
    newDashboardId: 0,
    isDashListLoaded: false,
    firstDashCreated: false
};

const slice = createSlice({
    name: 'dashboard',
    initialState,
    reducers: {
        getDashboardListSuccess(state, action) {
            state.dashboards = action.payload;
            state.isDashListLoaded = true;
        },
        getDashboardSuccess(state, action) {
            state.dashboard = action.payload;
            state.dashboardError = null;
        },
        getDashboardFailure(state, action) {
            state.dashboardError = action.payload;
        },
        setDefaultDashboardSuccess(state, action) {
            state.defaultDashboard = action.payload;
        },
        createDashboardSuccess(state, action) {
            state.newDashboardId = action.payload;
            state.isDashListLoaded = false;
        },
        updateDashboardSuccess(state, action) {
            state.dashboard = action.payload;
        },
        setFirstDashboard(state, action) {
            state.firstDashCreated = action.payload;
        },
        clearDashboard(state, action) {
            state.dashboard = null;
            state.firstDashCreated = false;
        }
    }
});

export default slice.reducer;

export function getDashboardList() {
    return async (dispatch) => {
        // Call API to get the list of dashboards for the logged in user - dashId, dashName
        const response = await axios.get(`/dashboards`).catch((error) => {
            if (error.response) {
                console.log(`${error.response.status} - ${error.response.data}`);
            } else if (error.request) {
                console.log(`${error.request}`);
            } else {
                console.log(`Error - ${error.message}`);
            }
        });
        if (response?.data) {
            dispatch(slice.actions.getDashboardListSuccess(response.data));
        }
    };
}

export function getDashboard(id) {
    return async (dispatch) => {
        // Call API to get the dashboard info for a given dashboard id
        try {
            const response = await axios.get(`/dashboards/${id}`);
            if (response?.data) {
                dispatch(slice.actions.getDashboardSuccess(response.data));
            }
        } catch (error) {
            dispatch(slice.actions.getDashboardFailure(error));
        }
    };
}

export function setDefaultDashboard(id) {
    return async (dispatch) => {
        dispatch(slice.actions.setDefaultDashboardSuccess(id));
    };
}

export function createDashboard({ name, displayOrder, layout }, isFirst) {
    return async (dispatch) => {
        try {
            const response = await axios.post('/dashboards', { name, displayOrder, layout });
            dispatch(slice.actions.createDashboardSuccess(response.data.id));
            if (isFirst) {
                dispatch(slice.actions.setFirstDashboard(true));
            }
        } catch (ex) {
            console.log(ex);
        }
    };
}

export function updateDashboard({ id, ownerId, name, displayOrder, layout }) {
    return async (dispatch) => {
        try {
            const response = await axios.put(`/dashboards/${id}`, { name, displayOrder, layout });
            if (response?.data) {
                dispatch(slice.actions.updateDashboardSuccess({ id, ownerId, name, displayOrder, layout }));
            }
        } catch (ex) {
            console.log(ex);
        }
    };
}

export function setFirstDashboard(isFirstDash) {
    return async (dispatch) => {
        dispatch(slice.actions.setFirstDashboard(isFirstDash));
    };
}

export function clearDashboard() {
    return async (dispatch) => {
        dispatch(slice.actions.clearDashboard(null));
    };
}

// export function layoutWillChange(isChanged) {
//     return () => {
//         dispatch(slice.actions.updateLayoutChange(isChanged));
//     };
// }
